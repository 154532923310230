import { graphql } from 'gatsby';
import React from 'react';
import MediaItem from '../media-item';
import TabletVertical from '../devices/tablet-vertical';
import Typography from '../typography';
import Color from '../colors';
import { borderRadius, breakpoints } from '../tokens';
import RowSet from '../grid/row-set';
import ScrollEffect from '../../animations/components/scroll-effect';
import Check from './check.svg';
import AspectRatio from '../aspect-ratio';

export type MediaItemWithDecorationProps = Queries.MediaItemWithDecorationPropsQuery & {
    orientation?: 'decoration-on-left' | 'decoration-on-right' | 'decoration-on-left-right-on-mobile'

};

const MediaItemWithDecoration = ({
    item,
    className,
    orientation = 'decoration-on-left',
    style,
    autoPlay,
    muted,
    objectFit,
    objectPosition,
    noAnimate,
}: MediaItemWithDecorationProps): JSX.Element => {
    const overlay = item?.[0]?.overlay?.[0];

    // eslint-disable-next-line no-underscore-dangle
    if (overlay && overlay.__typename === 'DatoCmsMediaItemCheckmarkOverlay') {
        return (
            <div
                css={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    paddingLeft: '110px',
                    [breakpoints.mb]: {
                        paddingLeft: '58px',
                        paddingRight: '48px',
                    },
                }}
                className={className}
            >
                <div css={{
                    maxWidth: '370px',
                    width: '100%',
                    position: 'relative',
                    margin: 'auto',
                }}
                >
                    <TabletVertical>
                        <MediaItem
                            css={{
                                height: '100%',
                            }}
                            item={item}
                            className={className}
                            style={style}
                            noAnimate={noAnimate}
                        />
                        <div
                            aria-hidden="true"
                            className="decoration"
                            css={{
                                position: 'absolute',
                                left: orientation === 'decoration-on-right' ? 'unset' : '-110px',
                                right: orientation !== 'decoration-on-right' ? 'unset' : '-110px',
                                bottom: '70px',
                                minWidth: '266px',
                                zIndex: '2',
                                [breakpoints.tb]: {
                                    transform: 'scale(0.8)',
                                },
                                [breakpoints.mb]: {
                                    transform: 'scale(0.5)',
                                    bottom: '0px',
                                    left: (() => {
                                        if (orientation === 'decoration-on-right' || orientation === 'decoration-on-left-right-on-mobile') {
                                            return 'unset';
                                        }
                                        return '-100px';
                                    })(),
                                    right: (() => {
                                        if (orientation !== 'decoration-on-right' && orientation !== 'decoration-on-left-right-on-mobile') {
                                            return 'unset';
                                        }
                                        return '-100px';
                                    })(),
                                },
                            }}
                        >
                            <RowSet breakpoints={{ dt: { between: 12 } }}>
                                {overlay.items && overlay.items.map(({ title, text }, idx) => (
                                    <ScrollEffect
                                        inViewProperties={{
                                            y: [20, 0],
                                            opacity: [0, 1],
                                        }}
                                        inViewDelay={idx * 0.3}
                                    >
                                        <Color
                                            backgroundColor="primaryPurple"
                                            color="shadesWhite"
                                            css={{
                                                padding: '18px',
                                                borderRadius: borderRadius.medium,
                                                boxShadow: '0 12px 24px 0px rgba(87, 28, 199, 0.28)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                [breakpoints.mb]: {
                                                    borderRadius: borderRadius.large,
                                                },
                                            }}
                                        >
                                            <Check />
                                            <div css={{ marginLeft: '15px' }}>
                                                <Typography
                                                    fontSize={{
                                                        dt: 1322,
                                                        mb: 1120,
                                                    }}
                                                    fontWeight="medium"
                                                >
                                                    {title}
                                                </Typography>
                                                <Typography
                                                    fontSize={{
                                                        dt: 1322,
                                                        mb: 1120,
                                                    }}
                                                    css={{
                                                        opacity: 0.65,
                                                    }}
                                                >
                                                    {text}
                                                </Typography>
                                            </div>
                                        </Color>
                                    </ScrollEffect>
                                ))}
                            </RowSet>
                        </div>
                    </TabletVertical>
                </div>
            </div>
        );
    }

    // eslint-disable-next-line no-underscore-dangle
    if (overlay && overlay.__typename === 'DatoCmsMediaItemQuoteOverlay') {
        const colourSet = overlay.colour?.[0]?.colour as { red: number, green: number, blue: number } | null;

        return (
            <div css={{
                maxWidth: '425px',
                marginRight: (orientation === 'decoration-on-left' || orientation === 'decoration-on-left-right-on-mobile') ? 'unset' : 'min(88px, 6vw)',
                marginLeft: orientation === 'decoration-on-right' ? 'unset' : 'min(88px, 6vw)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                [breakpoints.mb]: {
                    maxWidth: 'min(70%, 300px)',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    paddingBottom: '72px',
                },
            }}
            >
                <AspectRatio breakpoints={{ dt: { width: 425, height: 560 }, mb: { width: 425, height: 700 } }}>
                    <MediaItem
                        item={item}
                        css={{
                            borderRadius: borderRadius.extraLarge,
                            [breakpoints.tb]: {
                                borderRadius: borderRadius.semiLarge,
                            },
                            overflow: 'hidden',
                            transform: 'translateZ(0px)',
                            [breakpoints.mb]: {
                                marginLeft: orientation === 'decoration-on-left' ? 'unset' : 'min(-20%, -30px)',
                                marginRight: (orientation === 'decoration-on-right' || orientation === 'decoration-on-left-right-on-mobile') ? 'unset' : 'min(-20%, -30px)',
                            },
                        }}
                        noAnimate={noAnimate}
                        className={className}
                        style={style}
                    />
                    <div
                        className="decoration"
                        css={{
                            position: 'absolute',
                            right: (orientation === 'decoration-on-left' || orientation === 'decoration-on-left-right-on-mobile') ? 'unset' : 'max(-88px, -6vw)',
                            left: orientation === 'decoration-on-right' ? 'unset' : 'max(-88px, -6vw)',
                            bottom: 'min(36px, 2vw)',
                            maxWidth: 'min(248px, 60%)',
                            [breakpoints.tb]: {
                                bottom: '36px',
                            },
                            [breakpoints.mb]: {
                                left: (orientation === 'decoration-on-right' || orientation === 'decoration-on-left-right-on-mobile') ? 'unset' : 'min(-20%, -30px)',
                                right: orientation === 'decoration-on-left' ? 'unset' : 'min(-20%, -30px)',
                                maxWidth: 'min(248px, 100%)',
                                transform: 'scale(0.9)',
                                bottom: '-16%',
                            },
                        }}
                    >
                        <Color
                            backgroundColor="primaryOrange"
                            color="shadesWhite"
                            css={{
                                backgroundColor: `rgba(${colourSet?.red || '0'}, ${colourSet?.green || '0'}, ${colourSet?.blue || '0'}, 1)`,
                                padding: '18px',
                                borderRadius: borderRadius.large,
                                boxShadow: `0 12px 24px 0px rgba(${colourSet?.red || '0'}, ${colourSet?.green || '0'}, ${colourSet?.blue || '0'}, 0.28)`,
                                width: '100%',
                                [breakpoints.tb]: {
                                    borderRadius: borderRadius.semiLarge,
                                },
                            }}
                        >
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                    tb: 1420,
                                }}
                                css={{
                                    paddingBottom: '24px',
                                    [breakpoints.mb]: {
                                        paddingBottom: '8px',
                                    },
                                }}
                                fontWeight="medium"
                            >
                                <div>&#34;</div>
                                <div>
                                    {overlay.quote}
                                </div>
                            </Typography>
                            <Typography
                                fontSize={{
                                    dt: 1322,
                                }}
                                fontWeight="medium"
                            >
                                {overlay.attribution}
                            </Typography>
                            <Typography fontSize={{
                                dt: 1019,
                            }}
                            >
                                {overlay.role}
                            </Typography>
                        </Color>
                    </div>
                </AspectRatio>
            </div>
        );
    }

    return <MediaItem item={item} className={className} autoPlay={autoPlay} muted={muted} noAnimate={noAnimate} objectFit={objectFit} objectPosition={objectPosition} style={style} />;
};

export default MediaItemWithDecoration;

export const query = graphql`
    fragment MediaItemWithDecorationProps on DatoCmsMediaItemWithDecoration {
        media {
            __typename
            ... on DatoCmsImage {
                image {
                   ...DatoCmsInternalAsset
                }
            }
            ... on DatoCmsVideo {
                video {
                    ...DatoCmsInternalAsset
                }
            }
            ... on DatoCmsExternalVideo {
                externalVideo {
                    ...DatoCmsExternalAsset
                }
            }
        }
        overlay {
            __typename
            ... on DatoCmsMediaItemCheckmarkOverlay {
                items {
                    title
                    text
                }
            }
            ... on DatoCmsMediaItemQuoteOverlay {
                quote
                attribution
                role
                colour {
                    colour {
                        red
                        green
                        blue
                    }
                }
            }
        }
    }
`;
